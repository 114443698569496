import moment from 'moment-timezone'
import constants from 'helpers/constants'
import { cloneDeep } from 'lodash'

const RISKY_EVENTS_Y_AXIS_VALUES = {
    ONE_RISK_TYPE: [5],
    TWO_RISK_TYPES: [6.5, 3.5],
    THREE_RISK_TYPES: [7.5, 5, 2.5],
}

const GREY_RISK_COLOUR = -1

const motionAssessmentUtils = {
    computed: {
        hasPositiveThreshold () {
            return !!(this.selectedJointThresholds.positiveThreshold
                || this.selectedJointThresholds.positiveThreshold === 0)
        },

        hasNegativeThreshold () {
            return !!(this.selectedJointThresholds.negativeThreshold
                || this.selectedJointThresholds.negativeThreshold === 0)
        },

        selectedJointThresholds () {
            return {
                positiveThreshold: this.jointData ? this.jointData.positiveThreshold : null,
                negativeThreshold: this.jointData ? this.jointData.negativeThreshold : null,
            }
        },

        positiveThreshold () {
            return this.hasPositiveThreshold
                ? this.selectedJointThresholds.positiveThreshold
                : this.$t('na')
        },

        negativeThreshold () {
            return this.hasNegativeThreshold
                ? this.selectedJointThresholds.negativeThreshold
                : this.$t('na')
        },

        safeGetPositiveJointThreshold (jointThresholds) {
            return (jointThresholds.yellowPositiveThreshold
                || jointThresholds.yellowPositiveThreshold === 0)
                ? jointThresholds.yellowPositiveThreshold
                : undefined
        },

        safeGetNegativeJointThreshold (jointThresholds) {
            return (jointThresholds.yellowNegativeThreshold
                || jointThresholds.yellowNegativeThreshold === 0)
                ? jointThresholds.yellowNegativeThreshold
                : undefined
        },
    },

    methods: {
        // see https://momentjs.com/timezone/docs/#/zone-object/offset/ for why we do this to calculate the offset.
        // calling moment.tz.zone.utcOffset() gets the timezone and takes daylight savings time into account.
         calculateTimezoneOffset(assessmentStart, timezone) {
            return moment
                .tz((assessmentStart * constants.UNIX_MILLISECONDS), timezone)
                .utcOffset() / constants.SECONDS_IN_MINUTE
        },

        normalizeTimezoneFromChart (timestamp) {
            let timestampInSeconds = timestamp / constants.UNIX_MILLISECONDS
            let timezoneInSeconds = this.assessmentTimezoneOffset * constants.SECONDS_IN_HOUR
            let posixMinusTimezone = timestampInSeconds - timezoneInSeconds
            return posixMinusTimezone * constants.UNIX_MILLISECONDS
        },

        normalizeTimezoneForChart(timestamp) {
            let timestampInSeconds = timestamp / constants.UNIX_MILLISECONDS
            let timezoneInSeconds = this.assessmentTimezoneOffset * constants.SECONDS_IN_HOUR
            let posixMinusTimezone = timestampInSeconds + timezoneInSeconds
            return posixMinusTimezone * constants.UNIX_MILLISECONDS
        },

        timestampFormatted (timestamp) {
            return moment(timestamp).tz(this.assessmentTimezone).format('hh:mm:ss a')
        },

        parseRiskEvents (graph, jointKey = 0, numOfRisks, index, riskType) {
            let matchedGraph = cloneDeep(graph)
            return matchedGraph.map((point) => {
                // If pelvis, set the colour to grey since there is no associated risk
                const riskColor = riskType !== constants.ERGO_RISK_TYPES.PELVIS_FLEX ?
                    point[constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES][jointKey] :
                    GREY_RISK_COLOUR
                // The values pushed in the switch-case represent where the point will be placed on the y axis of the risky events graph
                // The number of selected risk types and the index of the current risk type in that array of selected risk types
                // will determine which y-axis value is appropriate
                // Ex. Selected risks are [leftShoulderInc, rightShoulderInc]
                //     Current risk in function is rightShoulderInc
                //     Case is '21' since there are 2 selected risks and the index is 1
                switch(numOfRisks.toString() + index.toString()) {
                    case ('20'):
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.TWO_RISK_TYPES[0])
                        break
                    case ('21'):
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.TWO_RISK_TYPES[1])
                        break
                    case ('30'):
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.THREE_RISK_TYPES[0])
                        break
                    case ('31'):
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.THREE_RISK_TYPES[1])
                        break
                    case ('32'):
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.THREE_RISK_TYPES[2])
                        break
                    default:
                        point.push(RISKY_EVENTS_Y_AXIS_VALUES.ONE_RISK_TYPE[0])
                        break
                }
                point.push(riskColor)
                return point
            })
        },

        convertPosixToLocalTime (assessmentTimezone, posixTimeStamp) {
            const timezoneInSeconds = assessmentTimezone * constants.SECONDS_IN_HOUR
            let posixPlusTimezone = posixTimeStamp + timezoneInSeconds
            return posixPlusTimezone * constants.UNIX_MILLISECONDS
        },

        stringConverter (string, module = "motion") {
            //takes a constant string and returns the i18n string
            return this.$t(`${module}Assessment.graphLabel.${string}`)
        },
    },
}


export default motionAssessmentUtils
