<template lang="pug">
    div(
        class="sz-CharacterViewer",
        id="CharacterViewer"
        @mousedown="startDrag",
        @mouseup="stopDrag",
        @mousemove="doDrag",
        @mouseleave="dragExit")
</template>

<script>
    import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
    import * as THREE from "three"
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'
    import { log } from 'util'
    import { Quaternion } from 'three'
    import { mapGetters } from 'vuex'

    export default {
        name: "CharacterViewer",

        props: {
            loadingGraph: {
                required: true,
                type: Boolean,
            },

            loadingAssessment: {
                required: false,
                type: Boolean,
            },

            quatKeys: {
                required: true,
                type: Object,
            },

            jointKeys: {
                required: true,
                type: Object,
            },

            showJointColoursOnly: {
                require: false,
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                src: '/assets/Robot_LB.glb',
                width: 0,
                height: 0,
                cameraYaw: 0,
                cameraPitch: 0,
                cameraDist: 4.5,
                cameraHeight: 0.9,
                canDrag: false,
                isDragging: false,
                isDisposing: false,
                dragX: 0,
                dragY: 0,
                renderer: null,
                camera:null,
                characterViewerPosition: null,
                characterViewerColors: null,
                currentPoint: null,
                isCompletePrime: false,
                savedEmitData: null,

            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),
        },

        watch: {
            isCompletePrime: function (val) {
                if (this.isCompletePrime && this.savedEmitData) {
                    this.updateCharacterViewer(this.savedEmitData)
                }
            },
        },

        created () {
            EventBus.$on('UPDATE_CHARACTER_VIEWER', this.updateCharacterViewer)
        },

        mounted () {
            this.primeCharacterViewer()

            if (this.currentPoint) {
                this.updateCharacterViewer(this.curentPoint)
            }
        },

        beforeDestroy () {
            this.disposeScene()

            EventBus.$off('UPDATE_CHARACTER_VIEWER')
        },

        methods: {
            primeCharacterViewer()
            {
                this.primeRenderer()
            },

            updateCharacterViewer (point) {
                if (!this.isCompletePrime) {
                    this.savedEmitData = point
                }
                else {
                    (this.showJointColoursOnly) ? this.setCharacterJointColours(point) : this.setCharacterViewerState(point)
                }
            },

            setCharacterJointColours (point) {
                if (point && point.length > 0) {
                    this.characterViewerColors = point
                    this.setJointColors(point)
                }

                if (point && point.length === 0) {
                    this.characterViewerColors = point
                    this.parseStreamedRiskColors(point)
                }

                this.renderScene()
            },

            setCharacterViewerState (point) {
                let quatIndex = this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE ? constants.ASSESSMENT_GRAPH_INDICES.POSTURE.QUAT_VALUES : constants.ASSESSMENT_GRAPH_INDICES.QUAT_VALUES
                let riskIndex = this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE ? constants.ASSESSMENT_GRAPH_INDICES.POSTURE.RISK_TYPE_VALUES : constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES

                this.currentPoint = point
                if (!this.loadingGraph) {
                    if (point.length > 0 && point[quatIndex].length > 0) {
                        const that = this
                        let joints = Object.values(constants.CHARACTER_VIEWER_JOINT_NAMES).reduce(function (jointAcc, joint) {
                            let quats = Object.values(constants.QUAT_JOINT_MAP[joint]).reduce(function (quatAcc, quaternion) {
                                const quatKey = that.quatKeys[quaternion]
                                const pointQuats = point[quatIndex]
                                quatAcc[quaternion] = pointQuats[quatKey]
                                return quatAcc
                            }, {})
                            jointAcc[joint] = quats
                            return jointAcc
                        }, {})

                        this.characterViewerPosition = joints

                        let quats = {}

                        for (let joint of Object.keys(joints)) {
                            let quat = Object.keys(joints[joint]).reduce(function (acc, quaternion) {
                                let quadrant = quaternion.split("_")[1]
                                acc[quadrant] = joints[joint][quaternion]
                                return acc
                            } , {})
                            quats[joint] = quat
                        }
                        this.setQuaternionsOnJoints(quats)

                        if (point && point[riskIndex].length > 0) {
                            this.characterViewerColors = point[riskIndex]
                            this.setJointColors(point[riskIndex])
                        }

                        if (point && point[riskIndex].length === 0) {
                            this.characterViewerColors = point[riskIndex]
                            this.parseStreamedRiskColors(point[riskIndex])
                        }

                        // DBG - update the pose here

                        this.renderScene()
                    }
                }
            },

            parseStreamedRiskColors (quatValues) {
                let jointColors = Object.values(constants.CHARACTER_VIEWER_JOINT_NAMES).reduce(function (acc, jointName) {
                    let jointValue = constants.CHARACTER_VIEWER_JOINT_QUAT_STREAM_JOINT_RISK_MAP[jointName].reduce(function (acc, jointRisk) {
                        if (quatValues[jointRisk] && quatValues[jointRisk] >= acc) {
                            acc = quatValues[jointRisk]
                        }
                        return acc
                    }, 0)

                    acc[jointName] = jointValue
                    return acc
                }, {})

                this.setJointColors(jointColors)
            },

            setJointColors (jointColors) {
                if (this.scene && !this.isDisposing) {
                    this.clearJointColors()
                    let joints = Object.keys(jointColors).reduce((joints, event, currentIndex) => {
                        let joint = Object.keys(this.jointKeys).find((key) => {
                            if (this.jointKeys[key] == event) {
                                return true
                            }
                        })

                        let jointColor = jointColors[event]
                        joint = constants.JOINT_CHARACTER_VIEWER_JOINT_MAP[joint]
                        if (joints[joint] !== null && jointColor < joints[joint]) {
                            jointColor = joints[joint]
                        }
                        return {...joints, [joint]: jointColor}
                    }, 0)
                    Object.keys(joints).map((joint) => {
                        let jointColor = joints[joint]
                        this.setJointColor(joint, constants.RISK_COLOR_CHARACTER_VIEWER_COMMAND_MAP[jointColor])
                    })
                }
            },

            setQuaternionsOnJoints(quats)
            {
                this.identityPose()

                var pelvisName = constants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS
                var ubackName = constants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK
                var lshoulderName = constants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER
                var lelbowName = constants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW
                var rshoulderName = constants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER
                var relbowName = constants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW

                this.setJointQuaternion(pelvisName, quats [pelvisName], new THREE.Quaternion())
                this.setJointQuaternion(ubackName, quats [ubackName], quats [pelvisName])
                this.setJointQuaternion(lshoulderName, quats [lshoulderName], quats [ubackName])
                this.setJointQuaternion(lelbowName, quats [lelbowName], quats [lshoulderName])
                this.setJointQuaternion(rshoulderName, quats [rshoulderName], quats [ubackName])
                this.setJointQuaternion(relbowName, quats [relbowName], quats [rshoulderName])
            },

            setJointQuaternion (jointName, quat, parentQuat) {
                if (this.scene && !this.isDisposing)
                {
                    const rotation = new THREE.Quaternion(quat.w, quat.x, quat.y, quat.z)
                    const parentRotation = new THREE.Quaternion(parentQuat.w, parentQuat.x, parentQuat.y, parentQuat.z)

                    if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS)
                    {
                        const pelvisyaw = this.getPelvisYawAnimationQuat(rotation)
                        this.applyJointRotation(this.getLeftLegMesh(), new THREE.Vector3(), this.getPelvisYawAnimationQuat(rotation))
                        this.applyJointRotation(this.getRightLegMesh(), new THREE.Vector3(), this.getPelvisYawAnimationQuat(rotation))

                        this.applyJointRotation(this.getPelvisMesh(), this.getPelvisPosition(), this.getPelvisAnimationQuat(rotation))
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK)
                    {
                        this.applyJointRotations(this.getUpperBackMesh(), this.getUpperBackPosition(), this.getPelvisAnimationQuat(parentRotation), this.getUpperBackWorldAnimationQuat(rotation))
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER)
                    {
                        this.applyJointRotations(this.getLeftShoulderMesh(), this.getLeftShoulderPosition(), this.getUpperBackWorldAnimationQuat(parentRotation), this.getLeftShoulderWorldAnimationQuat(rotation))
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW)
                    {
                        this.applyJointRotations(this.getLeftElbowMesh(), this.getLeftElbowPosition(), this.getLeftShoulderWorldAnimationQuat(parentRotation), this.getLeftElbowAnimationQuat(rotation))
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER)
                    {
                        this.applyJointRotations(this.getRightShoulderMesh(), this.getRightShoulderPosition(), this.getUpperBackWorldAnimationQuat(parentRotation), this.getRightShoulderWorldAnimationQuat(rotation))
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW)
                    {
                        this.applyJointRotations(this.getRightElbowMesh(), this.getRightElbowPosition(), this.getRightShoulderWorldAnimationQuat(parentRotation), this.getRightElbowAnimationQuat(rotation))
                   }
                }
            },


            clearJointColors() {
                var pelvis = this.getPelvisMesh()

                if (pelvis && this.pelvisMaterial) {
                    pelvis.material = this.pelvisMaterial
                }

                var upperBack = this.getUpperBackMesh()

                if (upperBack && this.upperBackMaterial) {
                    upperBack.material = this.upperBackMaterial
                }

                var leftShoulder = this.getLeftShoulderMesh()

                if (leftShoulder && this.leftShoulderMaterial) {
                    leftShoulder.material = this.leftShoulderMaterial
                }

                var leftElbow = this.getLeftElbowMesh()

                if (leftElbow && this.leftElbowMaterial) {
                    leftElbow.material = this.leftElbowMaterial
                }

                var rightShoulder = this.getRightShoulderMesh()

                if (rightShoulder && this.rightShoulderMaterial) {
                    rightShoulder.material = this.rightShoulderMaterial
                }

                var rightElbow = this.getRightElbowMesh()

                if (rightElbow && this.rightElbowMaterial) {
                    rightElbow.material = this.rightElbowMaterial
                }
            },

            setJointColor(jointName, jointColor) {
                if (this.scene && !this.isDisposing)
                {
                    if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.PELVIS)
                    {
                        var mesh = this.getPelvisMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.pelvisMaterial)
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.U_BACK)
                    {
                        var mesh = this.getUpperBackMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.upperBackMaterial)
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.L_SHOULDER)
                    {
                        var mesh = this.getLeftShoulderMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.leftShoulderMaterial)
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.L_ELBOW)
                    {
                        var mesh = this.getLeftElbowMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.leftElbowMaterial)
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.R_SHOULDER)
                    {
                        var mesh = this.getRightShoulderMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.rightShoulderMaterial)
                    }
                    else if (jointName == constants.CHARACTER_VIEWER_JOINT_NAMES.R_ELBOW)
                    {
                        var mesh = this.getRightElbowMesh()

                        mesh.material = this.getJointColorMaterial(jointColor, this.rightElbowMaterial)
                    }
                }
            },

            getCanvasDimensions () {
                let container = document.getElementById('CharacterViewer')
                let containerDimensions = container.getBoundingClientRect()
                let width = containerDimensions.width
                this.width = width
                this.height = width
            },

            startDrag () {
                if (this.camera == null) return

                this.renderScene()

                this.canDrag = true
                this.isDragging = false
                this.dragX = 0
                this.dragY = 0

                event.preventDefault()
            },

            stopDrag () {
                this.canDrag = false
                this.isDragging = false
            },

            doDrag (evt) {
                event.preventDefault()

                if (this.canDrag) {
                    if (this.isDragging)
                    {
                        var deltaX = evt.x - this.dragX
                        var deltaY = evt.y - this.dragY

                        this.cameraYaw -= deltaX / 50
                        this.cameraPitch -= deltaY / 50

                        this.cameraYaw = Math.max(-Math.PI, this.cameraYaw)
                        this.cameraYaw = Math.min(Math.PI, this.cameraYaw)

                        this.cameraPitch = Math.max(-Math.PI / 3, this.cameraPitch)
                        this.cameraPitch = Math.min(Math.PI / 3, this.cameraPitch)

                        var cameraPos = new THREE.Vector3(0, this.cameraHeight, this.cameraDist)

                        var rotYaw = new THREE.Quaternion()
                        rotYaw = rotYaw.setFromEuler(new THREE.Euler(0, this.cameraYaw, 0))

                        var rotPitch = new THREE.Quaternion()
                        rotPitch = rotPitch.setFromEuler(new THREE.Euler(this.cameraPitch, 0, 0))

                        cameraPos.applyQuaternion(rotPitch)
                        cameraPos.applyQuaternion(rotYaw)

                        this.camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z)
                        this.camera.lookAt(0,0.9,0)

                        this.dragX = evt.x
                        this.dragY = evt.y

                        if (this.grid)
                        {
                            // NOTE: This requires tuning if the height of the camera or lookat information is changed
                            this.grid.visible = this.cameraPitch <= 0.21
                        }

                        this.renderScene()
                    }
                    else
                    {
                        this.dragX = evt.x
                        this.dragY = evt.y
                        this.isDragging = true
                    }
                }
            },

            dragExit () {
                this.canDrag = false
                this.isDragging = false
                this.inElement = false
            },

            renderScene() {
                if (this.scene && this.renderer && this.camera)
                {
                    this.renderer.render(this.scene, this.camera)
                }
            },

            primeRenderer() {
                if (!this.scene && !this.isDisposing)
                {
                    this.createRenderer()

                    this.scene = new THREE.Scene()

                    this.scene.background = new THREE.Color(0x5090E0)
                    this.scene.fog = new THREE.Fog( this.scene.background, 1, 15 )

                    this.createCamera()
                    this.createLights()
                    this.createFloor()
                    this.createRobot()
                    this.createJointColors()
                    this.renderScene()
                }
            },

            disposeScene()
            {
                if (!this.isDisposing)
                {
                    if (this.scene)
                    {
                        // EGK - disposing is still not fully working
                        // EGK - seeing ongoing memory leaks from BufferGeometry and other types
                        // EGK - renderer is not disposed here properly due to what may be a bug in three.js
                        // EGK - unable to remove canvas at this point because canvas is no longer a child of the characterViewer
                        this.isDisposing = true

                        this.clearJointColors()
                        this.disposeSceneContents(this.scene)

                        if (this.greenMaterial) this.greenMaterial.dispose()
                        if (this.yellowMaterial) this.yellowMaterial.dispose()
                        if (this.orangeMaterial) this.orangeMaterial.dispose()
                        if (this.redMaterial) this.redMaterial.dispose()

                        this.robot = null

                        this.greenMaterial = null
                        this.yellowMaterial = null
                        this.orangeMaterial = null
                        this.redMaterial = null

                        this.grid = null
                        this.floor = null
                        this.floorShadow = null

                        this.mainLight = null
                        this.ambientLight = null
                        this.shadowLight = null

                        this.camera = null

                        this.scene.dispose()
                        this.scene = null

                        this.isDisposing = false
                    }
                }
            },

            createCamera() {
                const aspectRatio = 1
                const fieldOfView = 30
                const nearPlane = 0.1
                const farPlane = 1000

                this.camera = new THREE.PerspectiveCamera(
                    fieldOfView,
                    aspectRatio,
                    nearPlane,
                    farPlane
                )

                this.camera.position.z = this.cameraDist
                this.camera.position.y = this.cameraHeight
                this.camera.position.x = 0
                this.camera.lookAt(0,this.cameraHeight,0)
            },

            // EGK - This code needs later review
            // EGK - Unclear why the canvas is preserved, but without this, we start losing WebGLRenderingContexts
            createRenderer() {
                var characterViewer = this.getCharacterViewer()
                var canvas = characterViewer.firstChild
                var webgl = null

                if (canvas)
                {
                    var webgl = canvas.getContext('webgl')

                    if (!webgl)
                    {
                        var webgl = canvas.getContext('webgl2')
                    }
                }

                if (!this.renderer)
                {
                    if (canvas && webgl)
                    {
                        this.renderer = new THREE.WebGLRenderer({
                            antialias: true, // smoother edges
                            alpha: true, // this setting allows transparency
                            canvas: canvas,
                            context: webgl,
                        })
                    }
                    else
                    {
                        this.renderer = new THREE.WebGLRenderer({
                            antialias: true, // smoother edges
                            alpha: true, // this setting allows transparency
                        })
                    }

                    // support higher res displays
                    this.renderer.setPixelRatio(window.devicePixelRatio)

                    // transparent background
                    this.renderer.setClearColor(0x000000, 0xff)

                    // enable shadows
                    this.renderer.shadowMap.enabled = true

                    // Set size on screen (data drive this)
                    this.getCanvasDimensions()
                    this.renderer.setSize(this.width, this.height)
                }

                // Add renderer to VUE
                while (characterViewer.firstChild)
                {
                    characterViewer.removeChild(characterViewer.firstElementChild)
                }

                characterViewer.appendChild(this.renderer.domElement)
            },

            createLights () {
                this.createWorldLights()
                this.createShadowLight()
            },

            createWorldLights () {
                this.ambientLight = new THREE.HemisphereLight(
                    0x404040, // bright sky color
                    0x000000, // dim ground color
                    0.5 // intensity
                )

                this.mainLight = new THREE.DirectionalLight(0xdfdfdf, 2.0)

                this.mainLight.position.set(5, 10, 5)

                this.scene.add(this.ambientLight, this.mainLight)
            },

            createShadowLight () {
                this.shadowLight = new THREE.DirectionalLight(0xffffff, 1, 40)

                this.shadowLight.position.set(5, 10, 5)
                this.shadowLight.lookAt(0,1,0)

                // Allow shadow casting
                this.shadowLight.castShadow = true

                // define the visible area of the projected shadow
                this.shadowLight.shadow.camera.left = -20
                this.shadowLight.shadow.camera.right = 20
                this.shadowLight.shadow.camera.top = 20
                this.shadowLight.shadow.camera.bottom = -20

                this.shadowLight.shadow.camera.near = 0.1
                this.shadowLight.shadow.camera.far = 20

                // while increasing the size will improve quality,
                // it will also decrease performance
                this.shadowLight.shadow.mapSize.width = 1024
                this.shadowLight.shadow.mapSize.height = 1024

                this.scene.add(this.shadowLight)
            },

            createFloor () {
                this.floorShadow = new THREE.Mesh(
                    new THREE.PlaneGeometry(100, 100, 10, 10),
                    [
                        new THREE.ShadowMaterial(),
                    ]
                )

                this.floorShadow.rotateX(-Math.PI / 2)
                this.floorShadow.castShadow = false
                this.floorShadow.receiveShadow = true
                this.scene.add(this.floorShadow)

                this.floor = new THREE.Mesh(
                    new THREE.PlaneGeometry(100, 100, 10, 10),
                    [
                        new THREE.MeshBasicMaterial({
                            color: 0x303030,
                        }),
                    ]
                )

                this.floor.rotateX(-Math.PI / 2)
                this.floor.castShadow = false
                this.floor.receiveShadow = false
                this.scene.add(this.floor)

                this.grid = new THREE.GridHelper(20, 20, 0x606060, 0x606060)
                this.grid.castShadow = false
                this.grid.receiveShadow = true
                this.scene.add(this.grid)
            },

            createRobot() {
                var loader = new GLTFLoader()

				loader.load('/assets/Robot_LB.glb',
                    gltf => {
                        if (this.scene && !this.isDisposing)
                        {
                            this.storeRobot(gltf.scene)
                            this.scene.add( gltf.scene )

                            gltf.scene.traverse( function ( child ) {
                                if ( child.isMesh ) {
                                    child.castShadow = true
                                }
                            })

                            this.primeJointInfo()
                            //this.logScene()
                            //this.testPose()
                            //this.testJointColor()

                            if (this.renderer && this.camera)
                            {
                                this.renderer.render(this.scene, this.camera)
                            }
                            this.isCompletePrime = true
                        }
                    },
                    undefined,
                    undefined
                )
            },

            storeRobot(robot)
            {
                this.robot = robot
            },

            createJointColors() {
                this.greenMaterial = new THREE.MeshBasicMaterial( { color: 0x00B600 })
                this.yellowMaterial = new THREE.MeshBasicMaterial( { color: 0xFFFF00 })
                this.orangeMaterial = new THREE.MeshBasicMaterial( { color: 0xFFA500 })
                this.redMaterial = new THREE.MeshBasicMaterial( { color: 0xFF0000 })
            },

            primeJointInfo() {
                var pelvis = this.getPelvisMesh()
                var upperBack = this.getUpperBackMesh()
                var leftShoulder = this.getLeftShoulderMesh()
                var leftElbow = this.getLeftElbowMesh()
                var rightShoulder = this.getRightShoulderMesh()
                var rightElbow = this.getRightElbowMesh()

                if (pelvis) this.pelvisMaterial = pelvis.material
                if (upperBack) this.upperBackMaterial = upperBack.material
                if (leftShoulder) this.leftShoulderMaterial = leftShoulder.material
                if (leftElbow) this.leftElbowMaterial = leftElbow.material
                if (rightShoulder) this.rightShoulderMaterial = rightShoulder.material
                if (rightElbow) this.rightElbowMaterial = rightElbow.material
            },

            getJointColorMaterial(jointColor, defaultMaterial)
            {
                if (jointColor == 'Yellow') return this.yellowMaterial
                if (jointColor == 'Orange') return this.orangeMaterial
                if (jointColor == 'Red') return this.redMaterial

                return defaultMaterial
            },

            testJointColor() {
                var pelvis = this.getPelvisMesh()
                var upperBack = this.getUpperBackMesh()
                var leftShoulder = this.getLeftShoulderMesh()
                var leftElbow = this.getLeftElbowMesh()
                var rightShoulder = this.getRightShoulderMesh()
                var rightElbow = this.getRightElbowMesh()

                if (pelvis)
                {
                    pelvis.material = this.redMaterial
                    pelvis.material.needsUpdate = true
                }

                if (upperBack)
                {
                    upperBack.material = this.yellowMaterial
                    upperBack.material.needsUpdate = true
                }

                if (leftShoulder)
                {
                    leftShoulder.material = this.greenMaterial
                    leftShoulder.material.needsUpdate = true
                }

                if (leftElbow)
                {
                    leftElbow.material = this.greenMaterial
                    leftElbow.material.needsUpdate = true
                }

                if (rightShoulder)
                {
                    rightShoulder.material = this.orangeMaterial
                    rightShoulder.material.needsUpdate = true
                }

                if (rightElbow)
                {
                    rightElbow.material = this.orangeMaterial
                    rightElbow.material.needsUpdate = true
                }
            },

            identityPose()
            {
                this.setJointToIdentity(this.getLeftLegMesh(), new THREE.Vector3())
                this.setJointToIdentity(this.getRightLegMesh(), new THREE.Vector3())

                this.setJointToIdentity(this.getPelvisMesh(), this.getPelvisPosition())

                this.setJointToIdentity(this.getUpperBackMesh(), this.getUpperBackPosition())

                this.setJointToIdentity(this.getLeftShoulderMesh(), this.getLeftShoulderPosition())
                this.setJointToIdentity(this.getLeftElbowMesh(), this.getLeftElbowPosition())

                this.setJointToIdentity(this.getRightShoulderMesh(), this.getRightShoulderPosition())
                this.setJointToIdentity(this.getRightElbowMesh(), this.getRightElbowPosition())
            },

            testPose()
            {
                this.applyJointRotation(this.getLeftLegMesh(), new THREE.Vector3(), this.getPelvisYawAnimationQuatTest())
                this.applyJointRotation(this.getRightLegMesh(), new THREE.Vector3(), this.getPelvisYawAnimationQuatTest())

                this.applyJointRotation(this.getPelvisMesh(), this.getPelvisPosition(), this.getPelvisAnimationQuatTest())

                this.applyJointRotations(this.getUpperBackMesh(), this.getUpperBackPosition(), this.getPelvisAnimationQuatTest(), this.getUpperBackWorldAnimationQuatTest())

                this.applyJointRotations(this.getLeftShoulderMesh(), this.getLeftShoulderPosition(), this.getUpperBackWorldAnimationQuatTest(), this.getLeftShoulderWorldAnimationQuatTest())
                this.applyJointRotations(this.getLeftElbowMesh(), this.getLeftElbowPosition(), this.getLeftShoulderWorldAnimationQuatTest(), this.getLeftElbowAnimationQuatTest())

                this.applyJointRotations(this.getRightShoulderMesh(), this.getRightShoulderPosition(), this.getUpperBackWorldAnimationQuatTest(), this.getRightShoulderWorldAnimationQuatTest())
                this.applyJointRotations(this.getRightElbowMesh(), this.getRightElbowPosition(), this.getRightShoulderWorldAnimationQuatTest(), this.getRightElbowAnimationQuatTest())
            },

            getPelvisYawAnimationQuatTest()
            {
                var rotation = new THREE.Quaternion(0.708, -0.047, -0.155, 0.688)
                rotation = new THREE.Quaternion(0.59, 0.03, -0.06, -0.8)

                return this.getPelvisYawAnimationQuat(rotation)
            },


            getPelvisYawAnimationQuat(rotation)
            {
                return this.getAnimationYawQuat(rotation)
            },

            getPelvisAnimationQuatTest()
            {
                var rotation = new THREE.Quaternion(0.708, -0.047, -0.155, 0.688)
                rotation = new THREE.Quaternion(0.59, 0.03, -0.06, -0.8)

                return this.getPelvisAnimationQuat(rotation)
            },

            getPelvisAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            getUpperBackWorldAnimationQuatTest()
            {
                var upperBackQuat = new THREE.Quaternion(0.663, 0.0820, -0.112, 0.736)
                upperBackQuat = new THREE.Quaternion(0.56, 0.1, -0.15, -0.81)

                return this.getUpperBackWorldAnimationQuat(upperBackQuat)
            },

            getUpperBackWorldAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            getLeftShoulderWorldAnimationQuatTest()
            {
                var leftShoulderQuat = new THREE.Quaternion(0.980, 0.1300, -0.004, 0.15)
                leftShoulderQuat = new THREE.Quaternion(-0.2, 0.16, 0.17, 0.95)

                return this.getLeftShoulderWorldAnimationQuat(leftShoulderQuat)
            },

            getLeftShoulderWorldAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            getLeftElbowAnimationQuatTest()
            {
                var leftElbowQuat = new THREE.Quaternion(0.892, 0.0710, 0.2310, -0.382)
                leftElbowQuat = new THREE.Quaternion(0.27, -0.29, 0.17, 0.9)

                return this.getLeftElbowAnimationQuat(leftElbowQuat)
            },

            getLeftElbowAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            getRightShoulderWorldAnimationQuatTest()
            {
                var rightShoulderQuat = new THREE.Quaternion(0.357, -0.445, 0.0550, 0.819)
                rightShoulderQuat = new THREE.Quaternion(0.8, 0.23, 0.1, -0.54)

                return this.getRightShoulderWorldAnimationQuat(rightShoulderQuat)
            },

            getRightShoulderWorldAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            getRightElbowAnimationQuatTest()
            {
                var rightElbowQuat = new THREE.Quaternion(-0.23, -0.171, 0.4620, 0.84)
                rightElbowQuat = new THREE.Quaternion(0.92, 0.21, -0.19, 0.28)

                return this.getAnimationQuat(rightElbowQuat)
            },

            getRightElbowAnimationQuat(rotation)
            {
                return this.getAnimationQuat(rotation)
            },

            applyJointRotation(mesh, position, rotation)
            {
                if (mesh)
                {
                    this.setJointRotation(mesh, position, rotation)
                }
            },

            applyJointRotations(mesh, position, parentRotation, localRotation)
            {
                if (mesh)
                {
                    this.setJointRotations(mesh, position, parentRotation, localRotation)
                }
            },

            setJointRotation(mesh, position, rotation)
            {
                var matrixPreTranslate = new THREE.Matrix4()
                var matrixPostTranslate = new THREE.Matrix4()
                var matrixRotate = new THREE.Matrix4()

                matrixPreTranslate.makeTranslation(position.x, position.y, position.z)
                matrixRotate.makeRotationFromQuaternion(rotation)
                matrixPostTranslate.makeTranslation(-position.x, -position.y, -position.z)

                var matrix = new THREE.Matrix4()
                matrix.multiplyMatrices(matrix, matrixPreTranslate)
                matrix.multiplyMatrices(matrix, matrixRotate)
                matrix.multiplyMatrices(matrix, matrixPostTranslate)

                mesh.applyMatrix4(matrix)
                mesh.rotation.order = 'XYZ'
                mesh.matrixAutoUpdate = false
            },

            setJointRotations(mesh, position, parentRotation, localRotation)
            {
                var matrixPreTranslate = new THREE.Matrix4()
                var matrixPostTranslate = new THREE.Matrix4()
                var matrixParentRotate = new THREE.Matrix4()
                var matrixLocalRotate = new THREE.Matrix4()

                matrixPreTranslate.makeTranslation(position.x, position.y, position.z)
                matrixParentRotate.makeRotationFromQuaternion(parentRotation.conjugate())
                matrixLocalRotate.makeRotationFromQuaternion(localRotation)
                matrixPostTranslate.makeTranslation(-position.x, -position.y, -position.z)

                var matrix = new THREE.Matrix4()
                matrix.multiplyMatrices(matrix, matrixPreTranslate)
                matrix.multiplyMatrices(matrix, matrixParentRotate)
                matrix.multiplyMatrices(matrix, matrixLocalRotate)
                matrix.multiplyMatrices(matrix, matrixPostTranslate)

                mesh.applyMatrix4(matrix)
                mesh.matrixAutoUpdate = false
            },

            setJointToIdentity(mesh, position)
            {
                if (mesh)
                {
                    var matrix = mesh.matrix

                    var matrixInverse = new THREE.Matrix4()

                    matrixInverse.getInverse(matrix)

                    mesh.applyMatrix4(matrixInverse)
                    mesh.matrixAutoUpdate = false
                }
            },

            getTestQuat()
            {
                var rotation = new THREE.Quaternion()

                rotation.setFromEuler(new THREE.Euler(0, 0, Math.PI * 3 / 2))

                return rotation
            },

            getQuatFromAngles(pitch, yaw, roll)
            {
                var euler = new THREE.Euler(pitch * Math.PI / 180, yaw * Math.PI / 180, roll * Math.PI / 180)
                var quat = new THREE.Quaternion()

                quat.setFromEuler(euler)

                return quat
            },

            getAnimationQuat(rotation)
            {
                return this.convertLeftHandedToRightHandedQuaternion(rotation)
            },

            getAnimationYawQuat(rotation)
            {
                var quat = this.convertLeftHandedToRightHandedQuaternion(rotation)

                var up = new THREE.Vector3(0, 1, 0)
                var unit = new THREE.Vector3(0, 0, 1)
                var norm = new THREE.Vector3(unit.x, unit.y, unit.z)

                norm.applyQuaternion(quat)
                norm.y = 0

                if (norm.length < 0.001) norm = new THREE.Vector3(unit.x, unit.y, unit.z)
                else norm.normalize()

                var angle = norm.angleTo(unit)

                if (norm.x < 0) angle = -angle

                var result = new THREE.Quaternion()

                result.setFromAxisAngle(up, angle)

                return result
            },

            convertLeftHandedToRightHandedQuaternion(rotation)
            {
                var x = rotation.y // y
                var y = rotation.z // z
                var z = rotation.w // w
                var w = rotation.x // x

                var quat = new THREE.Quaternion(x, y, z, w)
                var mat = new THREE.Matrix4()

                mat.set(
                    -1, 0, 0, 0,
                     0, 0, 1, 0,
                     0, 1, 0, 0,
                     0, 0, 0, 1
                )

                var qt = new THREE.Quaternion()

                qt.setFromRotationMatrix(mat)

                var result = new THREE.Quaternion()

                result.multiplyQuaternions(qt.inverse(), quat)
                result.multiplyQuaternions(result, qt)

                return result
            },

            getLeftLegMesh()
            {
                return this.scene.getObjectByName( "Robot_LeftUpLegPivot", true )
            },

            getRightLegMesh()
            {
                return this.scene.getObjectByName( "Robot_RightUpLegPivot", true )
            },

            getPelvisMesh()
            {
                return this.scene.getObjectByName( "Robot_LoTorso", true )
            },

            getUpperBackMesh()
            {
                return this.scene.getObjectByName( "Robot_TorsoPivot", true )
            },

            getLeftShoulderMesh()
            {
                return this.scene.getObjectByName( "Robot_LeftUpArmPivot", true )
            },

            getLeftElbowMesh()
            {
                 return this.scene.getObjectByName( "Robot_LeftElbowPivot", true )
            },

            getRightShoulderMesh()
            {
                return this.scene.getObjectByName( "Robot_RightUpArmPivot", true )
            },

            getRightElbowMesh()
            {
                return this.scene.getObjectByName( "Robot_RightForearmPivot", true )
            },

            // These positions are taken from joints in Unity 2019.14f1 from the FBX version of the robot
            // The Left and Right Arms require negating the X value in the position
            // The meshes are baked in world space so these positions are required for proper rotation
            getPelvisPosition()
            {
                var position = new THREE.Vector3()

                position.set(3.276138e-06, 0.8690154, -0.004031228)

                return position
            },

            getUpperBackPosition()
            {
                var position = new THREE.Vector3()

                position.set(-3.276138e-06, 0.2443458, 0.01438576)
                position.add(this.getPelvisPosition())

                return position
            },

            getUpperTorsoPosition()
            {
                var position = new THREE.Vector3()

                position.set(5.934439e-05, 0.03619873, 0.003856965)
                position.add(this.getUpperBackPosition())

                return position
            },

            getLeftShoulderPosition()
            {
                var position = new THREE.Vector3()

                position.set(0.184509, 0.1969663, -0.06950528)
                position.add(this.getUpperTorsoPosition())

                return position
            },

            getLeftUpperArmPosition()
            {
                var position = new THREE.Vector3()

                position.set(0.1209733, -0.002728181, -0.005106006)
                position.add(this.getLeftShoulderPosition())

                return position
            },

            getLeftElbowPosition()
            {
                var position = new THREE.Vector3()

                position.set(0.1437622, -0.004230858, -0.01293322)
                position.add(this.getLeftUpperArmPosition())

                return position
            },

            getRightShoulderPosition()
            {
                var position = new THREE.Vector3()

                position.set(-0.1843903, 0.1969663, -0.06950528)
                position.add(this.getUpperTorsoPosition())

                return position
            },

            getRightUpperArmPosition()
            {
                var position = new THREE.Vector3()

                position.set(-0.1213217, -0.00388698, 0.0008436804)
                position.add(this.getRightShoulderPosition())

                return position
            },

            getRightElbowPosition()
            {
                var position = new THREE.Vector3()

                position.set(-0.146735, -0.003600334, -0.01394448)
                position.add(this.getRightUpperArmPosition())

                return position
            },

            logScene() {
                this.scene.traverse( this.traverser )
            },

            getCharacterViewer()
            {
                var containers = document.getElementsByClassName('sz-CharacterViewer')

                if (containers)
                {
                    if (containers.length > 0)
                    {
                       return containers[0]
                    }
                }

                return null
           },

            disposeSceneContents(scene)
            {
                scene.traverse(o => {
                    if (o.geometry) {
                        o.geometry.dispose()
                    }

                    if (o.material) {
                        if (o.material.length) {
                            for (let i = 0; i < o.material.length; ++i) {
                                o.material[i].dispose()
                            }
                        }
                        else {
                            o.material.dispose()
                        }
                    }
                })
            },

            traverser( obj ) {
                var s = '|___'

                var obj2 = obj

                while ( obj2 !== this.scene ) {

                    s = '\t' + s

                    obj2 = obj2.parent

                }

                log( s + obj.name + ' <' + obj.type + '>' )
            },
        },
    }
</script>

<style lang="scss" scoped>
    #CharacterViewer {
        display: inline-block;
    }
</style>
