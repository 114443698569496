<template lang="pug">
    div(class="sz-CopyableText")
        div(
            :id="text",
            class="sz-CopyableText-text") {{ displayText }}
        div(
            class="sz-CopyableText-copy",
            @click.stop.prevent="copyText")
                SvgIcon(
                    icon="copy",
                    width="1.25rem",
                    height="1.25rem")
</template>

<script>
    import SvgIcon from 'components/Shared/SvgIcon'
    import CloneDeep from 'lodash'

    export default {
        name: "CopyableText",

        components: {
            SvgIcon,
        },

        props: {
            text: {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                copied: false,
            }
        },

        computed: {
            displayText () {
                return this.copied ? this.$t(`shared.copiedAssessmentID`) : this.text
            },
        },

        watch: {
            copied: function() {
                if (this.copied === true) {
                    // Re-display the assessmentID after 1 second
                    setTimeout(() => {
                        this.copied = false
                    }, 1000)
                }
            },
        },

        methods: {
            copyText () {
                let copyText = document.createElement('textarea')
                document.body.appendChild(copyText)
                copyText.value = this.text
                copyText.select()

                var success = document.execCommand('copy')
                this.copied = success ? true : false
                document.body.removeChild(copyText)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-CopyableText {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        width: 96%;
        border-width: 1px;
        border-style: solid;
        padding: 0.25rem 0.25rem 0.25rem 0.25rem;

        &-text {
            @extend %font-topbar-heading;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            align-self: center;
        }

        &-copy {
            margin-top: 0.25rem;
            align-self: center;
            cursor: pointer;

            &:hover {
                color: $color-base-grey;
            }
        }
    }
</style>
